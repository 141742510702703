<template>
  <div>
    <div class="min-vh-100">
      <CRow class="no-gutters px-3 px-sm-0">
        <b-col xl="6" class="text-center text-sm-left mb-3 mb-sm-0">
          <h1 class="mr-sm-4 header-main text-uppercase">
                รายละเอียด เดือน <span class="mr-1">{{ new Date(filter.dateTime) | moment("MMMM")}}</span>
                (<span>{{ new Date(filter.dateTime) | moment("D")}}</span> - 
                <span>{{daysInMonth}} {{ new Date(filter.dateTime) | moment("MMM YYYY")}}</span>)
            </h1>
        </b-col>
        <b-col xl="6" class="text-right">
          <div class="d-flex justify-content-end">
            <b-input-group class="panel-input-serach">
              <b-form-input
                class="input-serach"
                placeholder="ค้นหา"
                v-model="filter.search"
                @keyup="handleSearch"
              ></b-form-input>
              <b-input-group-prepend @click="btnSearch">
                <span class="icon-input m-auto pr-2">
                  <font-awesome-icon icon="search" title="View" />
                </span>
              </b-input-group-prepend>
            </b-input-group>

            <b-button v-b-toggle.sidebar-1 class="btn-filter">
              <font-awesome-icon
                icon="filter"
                title="filter-btn"
                class="text-white mr-0 mr-sm-1"
              />
              <span class="d-none d-sm-inline"
                >ค้นหา ({{ countOrderBy }})</span
              >
            </b-button>
            <b-button
              class="btn-main h-100"
              @click="exportData"
              >ส่งออกข้อมูลเงินคืนในระบบ</b-button
            >
              <!-- :disabled="exportFilter.partnerId.length == 0" -->
          </div>
        </b-col>
      </CRow>
      <b-sidebar
        id="sidebar-1"
        title="ค้นหาแบบละเอียด"
        backdrop
        shadow
        backdrop-variant="dark"
        right
        ref="filterSidebar"
      >
        <div class="px-3 py-2">
          <div class="text-right">
            <button
              type="button"
              class="btn btn-link px-0"
              @click="onClearFilter()"
            >
              ล้างค่า
            </button>
          </div>

          <InputSelect
            class="mb-4"
            title="เรียงตาม"
            name="orderBy"
            :options="optionsOrderBy"
            v-model="filter.sortByOrderOrId"
            @onDataChange="handleChangeOrderBy"
            valueField="value"
            textField="name"
          />

          <div class="text-center mt-4">
            <button
              type="button"
              class="btn btn-purple button"
              @click="getDataFilter"
            >
              ค้นหา
            </button>
          </div>
        </div>
      </b-sidebar>

      <div class="mt-3 bg-white">
        <b-row class="no-gutters px-3 pt-3 pt-sm-0 px-sm-0">
          <b-col>
            <b-table
              class="table-list"
              striped
              responsive
              hover
              :items="items"
              :fields="fields"
              :busy="isBusy"
              show-empty
              empty-text="ไม่พบข้อมูล"
            >
              <!-- <template v-slot:cell(ids)="data">
                <b-form-checkbox
                  size="lg"
                  class="ml-3"
                  :value="data.item.startDate"
                  v-model="selected"
                ></b-form-checkbox>
              </template>
              <template v-slot:head(ids)="data">
                <b-form-checkbox
                  size="lg"
                  class="ml-3"
                  :ref="data.field.key"
                  v-model="selectedAll"
                  @change="onChangeSelectedAll"
                ></b-form-checkbox>
              </template> -->
              <template v-slot:cell(updatedTime)="data">
                <span>{{
                  new Date(data.item.updatedTime) | moment($formatDateTime)
                }}</span>
              </template>
              <template v-slot:cell(name)="data">
                <div>
                  <p class="m-0">{{ data.item.firstname }} {{ data.item.lastname }}</p>
                </div>
              </template>
              <template v-slot:cell(withdrawNumber)="data">
                <div v-if="data.item.withdrawNumber == ''">-</div>
                <div v-else>{{ data.item.withdrawNumber }}</div>
              </template>
              <template v-slot:cell(cashback)="data">
                <p class="m-0">{{ data.item.cashback | numeral("0,0") }}</p>
              </template>
              <template v-slot:cell(year)="data">
                <span>{{ new Date(data.item.startDate) | moment("YYYY")}}</span>
              </template>
              <template v-slot:cell(action)="data">
                <div class="d-flex justify-content-center">
                  <router-link :to="`/${data.item.typeId == 4 ? 'affiliate' : 'member'}/details/${data.item.userGUID}`">
                    <b-button
                      variant="link"
                      class="px-1 py-0 btn-link text-dark"
                    >
                      ดูรายละเอียด
                    </b-button>
                  </router-link>
                </div>
              </template>
              <template v-slot:cell(status)="data">
                <div :class="[data.item.statusId == 0 ? 'text-danger':'text-success']">
                  {{data.item.status}}
                </div>
              </template>
              <template v-slot:table-busy>
                <div class="text-center text-black my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong class="ml-2">Loading...</strong>
                </div>
              </template>
            </b-table>
          </b-col>
        </b-row>
        <b-row class="no-gutters px-3 px-sm-0">
          <b-col
            class="form-inline justify-content-center justify-content-sm-between"
          >
            <div class="d-sm-flex m-3">
              <b-pagination
                v-model="filter.pageNo"
                :total-rows="rows"
                :per-page="filter.perpage"
                class="m-md-0"
                @change="pagination"
                align="center"
              ></b-pagination>

              <div class="ml-2">
                <p class="total-record-paging text-nowrap text-center">
                  {{ totalRowMessage }}
                </p>
              </div>
            </div>

            <b-form-select
              class="mr-sm-3 select-page"
              v-model="filter.perpage"
              @change="hanndleChangePerpage"
              :options="pageOptions"
            ></b-form-select>
          </b-col>
        </b-row>
      </div>
      <div class="mt-3 bg-white">
        <b-row class="p-3">
          <b-col md="6">
            <router-link to="/cashback">
              <b-button :disabled="isDisable" class="btn-details-set btn-cancel"
                >ย้อนกลับ</b-button
              >
            </router-link>
          </b-col>
        </b-row>
      </div>
    </div>

    <ModalAlertError ref="modalAlertError" :text="modalMessage" />
    <ModalLoading ref="modalLoading" :hasClose="false" />
    <ModalAlert ref="modalAlert" :text="modalMessage" />
  </div>
</template>

<script>
import axios from "axios";
import * as moment from "moment/moment";
import ModalAlertError from "@/components/modal/alert/ModalAlertError";
import ModalLoading from "@/components/modal/alert/ModalLoading";
import ModalAlert from "@/components/modal/alert/ModalAlert";
import ModalAlertConfirm from "@/components/modal/alert/ModalAlertConfirm";
import InputSelect from "@/components/inputs/InputSelect";

export default {
  name: "WithdrawIndex",
  components: {
    ModalAlertError,
    ModalLoading,
    ModalAlert,
    ModalAlertConfirm,
    InputSelect
  },
  data() {
    return {
      selected: null,
      options: [],
      modalMessage: "",
      activeItem: 1,
      requestDeleteUser: {
        userId: null,
      },
      fields: [
        // {
        //   key: "ids",
        //   label: ""
        // },
        {
          key: "updatedTime",
          label: "อัพเดทล่าสุด",
          class: "w-100px",
        },
        {
          key: "name",
          label: "ชื่อ - นามสกุล เจ้าของเงินคืน",
          class: "w-100px",
        },
        {
          key: "cashback",
          label: "จำนวนเงินคืนคงเหลือ",
          class: "w-100px",
        },
        // {
        //   key: "typeUser",
        //   label: "ประเภท",
        //   class: "w-100px",
        // },
        {
          key: "status",
          label: "สถานะบัญชี",
          class: "w-100px",
        },
        {
          key: "action",
          label: "",
          class: "w-100px",
        },
      ],
      items: [],
      isBusy: false,
      value: false,
      rows: 0,
      filter: {
        type: [],
        status: [],
        pageNo: 1,
        perpage: 10,
        search: "",
        sortByOrderOrId:0,
        dateTime: ""
      },
      filterSubmit: {
        type: [],
        status: [],
        pageNo: 1,
        perpage: 10,
        search: "",
        sortByOrderOrId:0,
        dateTime: ""
      },
      pageOptions: [
        { value: 10, text: "10 / หน้า" },
        { value: 30, text: "30 / หน้า" },
        { value: 50, text: "50 / หน้า" },
        { value: 100, text: "100 / หน้า" },
      ],
      totalRowMessage: "",
      items: [],
      isBusy: false,
      isDisable: false,
      checkAll: false,
      selectAllCb: false,
      errorDate: false,
      selected: [],
      selectedAll: false,
      allItems: [],
      amount: 0,
      optionsOrderBy: [
        {value: 0, name: "วันที่"},
        {value: 1, name: "ชื่อ"}
      ],
    };
  },
  computed: {
    daysInMonth: function () {
      return moment(this.filter.dateTime, this.$formatDate).daysInMonth();
    },
    countOrderBy: function () {
      var count = 0;
      if (this.filterSubmit.sortByOrderOrId > -1) count += 1;
      else if (count > 0) count -= 1;
      return count;
    },
  },
  created: async function () {
    if(this.$route.params.date) this.filter.dateTime = this.$route.params.date;

    await this.getList();
    await this.getAllData();
    this.$isLoading = true;
    this.activeItem = 1;
  },
  watch: {
    selected: function () {
      if (this.selected.length == this.allItems.count) {
        this.selectedAll = true;
      } else {
        this.selectedAll = false;
      }
    },
    filterSubmit: function () {
      if(this.selectedAll){
        this.handleSelectedAll(this.selectedAll);
      }
    },
  },
  methods: {
    onChangeSelectedAll: async function (e){
      await this.handleSelectedAll(e);
    },
    handleSelectedAll: async function (e){
      this.selectedAll = e;
      if(e){
        await this.getAllData();
        this.selected = [];
        this.allItems.dataList.forEach((element, index) => {
            this.selected.push(element.startDate);
        });
      } else {
        this.selected = [];
      } 
    },
    getAllData: async function (tab = false) {
      return;
      
      let filterAll = {
        search: "",
        sortByOrderOrId: 0,
        pageNo: 1,
        perpage: -1,
        type:[]
      };
      filterAll.sortByOrderOrId = this.filter.sortByOrderOrId
      
      let resData = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/cashback/expire/detail`,
        null,
        this.$headers,
        filterAll
      );
      if (resData.result == 1) {
        this.allItems = resData.detail;
      }
    },
    getList: async function (tab = false) {
      this.isBusy = true;
      var filter = {...this.filter};
      filter.type = [];
      if(tab) {
        filter.search = "";
        filter.pageNo = 1;
      }

      this.filterSubmit = {...filter};

      let resData = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/cashback/expire/detail`,
        null,
        this.$headers,
        this.filterSubmit
      );
      if (resData.result == 1) {
        this.items = resData.detail.dataList;
        this.rows = resData.detail.count;
        this.isBusy = false;
      }
    },
    getDataFilter() {
      this.filter.pageNo = 1;
      this.$refs.filterSidebar.hide(true);
      this.getList();
    },
    onClearFilter() {
      this.filter.pageNo = 1;
      this.filter.sortByOrderOrId = 0;
      this.$refs.filterSidebar.hide(true);
      this.getList();
    },
    pagination(Page) {
      this.filter.pageNo = Page;
      this.getList();
    },
    hanndleChangePerpage(value) {
      this.filter.pageNo = 1;
      this.filter.perpage = value;
      this.getList();
    },
    handleSearch(e) {
      if (e.keyCode === 13) {
        this.filter.pageNo = 1;
        this.getList();
      }
    },
    btnSearch() {
      this.filter.pageNo = 1;
      this.getList();
    },
    exportData: async function() {
      this.$refs.modalLoading.show();

      axios({
        url: `${this.$baseUrl}/api/cashback/expire/detail/export`,
        method: "post",
        headers: this.$headers,
        responseType: "blob",
        data: this.filterSubmit
      })
        .then(response => {
          this.$refs.modalLoading.hide();
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");
          var dateExcel = moment().format("DDMMYYYYhhmmss");

          fileLink.href = fileURL;
          fileLink.setAttribute(
            "download",
            `Cashback-Expire-List-` + dateExcel + `.xlsx`
          );
          document.body.appendChild(fileLink);
          fileLink.click();
        })
        .catch(error => {
          console.log(error);
        });
    },
    handleChangeOrderBy: async function (value) {
      this.filter.sortByOrderOrId = value;
    },
  },
};
</script>       

<style scoped>
.menuactive {
  color: #ffb300 !important;
}

.review-shortdesc {
  position: absolute;
  top: 50%;
  left: 50%;
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  width: 100%;
}

.w-300 {
  width: 300px;
}

.finance-box {
  border-radius: 10px;
}

.text-orange {
  color: #f3591f;
}

.bgc-orange {
  background-color: #f3591f;
  color: white;
}

.w-120 {
  width: 120px;
}
</style>